import { User } from '../app/core/models/user';
import { Country } from '../app/core/models/country';
import { UserRoles } from '../app/core/models/user-roles';
import { EmailFrequency } from 'app/core/models/UserSettings';

export const CdArabCountry: Country = {
  country: 'AE',
  language: ['en_AE'],
  defaultLanguage: 'en_AE'
};

export const CdGBCountry: Country = {
  country: 'GB',
  language: ['en_GB'],
  defaultLanguage: 'en_GB'
};

export const CdUSCountry: Country = {
  country: 'US',
  language: ['en_US'],
  defaultLanguage: 'en_US'
};

export const CdDECountry: Country = {
  country: 'DE',
  language: ['de_DE'],
  defaultLanguage: 'de_DE'
};

export const CdArabUser: User = {
  gid: 'SE_AE_SIM_ALL',
  simulator: true,
  email: 'se.ae.sim.all@testuser.test',
  city: null,
  street: null,
  phone: null,
  mobile: null,
  salutation: null,
  firstName: 'John',
  lastName: 'Doe',
  countries: [CdArabCountry],
  termsAccepted: '2009-12-31T00:00:00',
  lastLogin: '2018-11-20T08:58:10',
  cookiesAccepted: null,
  language: 'en',
  country: 'AE',
  title: null,
  isInternalUser: true,
  marketingConsent: true
};

export const CdGBUser: User = {
  gid: 'SE_GB_SIM_ALL',
  simulator: true,
  email: 'se.gb.sim.all@testuser.test',
  city: null,
  street: null,
  phone: null,
  mobile: null,
  salutation: null,
  firstName: 'John',
  lastName: 'Doe',
  countries: [CdGBCountry],
  termsAccepted: '2009-12-31T00:00:00',
  lastLogin: '2018-11-20T08:58:10',
  cookiesAccepted: null,
  language: 'en',
  country: 'GB',
  title: null,
  isInternalUser: true,
  marketingConsent: true
};

export const CdUSUser: User = {
  gid: 'SE_US_SIM_ALL',
  simulator: true,
  email: 'se.us.sim@testuser.com',
  city: null,
  street: null,
  phone: null,
  mobile: null,
  salutation: null,
  firstName: 'John',
  lastName: 'Doe',
  countries: [CdUSCountry],
  termsAccepted: '2009-12-31T00:00:00',
  lastLogin: '2018-11-21T08:31:38',
  cookiesAccepted: null,
  language: 'en',
  country: 'US',
  title: null,
  isInternalUser: true,
  marketingConsent: true
};

export const CdAdminUser: User = {
  gid: 'Z00264FH',
  simulator: false,
  email: 'admin@admin.de',
  city: 'Abc',
  street: 'Strasse',
  phone: '+123 234543',
  mobile: null,
  salutation: 'mr',
  firstName: 'Admin',
  lastName: 'Doe',
  countries: [CdUSCountry, CdDECountry, CdGBCountry],
  termsAccepted: '2017-08-29T08:36:56',
  lastLogin: '2018-11-21T08:33:39',
  cookiesAccepted: null,
  language: 'en',
  country: 'US',
  title: null,
  isInternalUser: true,
  marketingConsent: true
};

export const CdUsers = [CdGBUser, CdArabUser, CdUSUser, CdAdminUser];

export const CdArabUserRoles: UserRoles[] = [
  { identifier: 'ER_LN_USER' },
  // { identifier: 'ER_LN_CUSTOMER_ADMIN' },
  // { identifier: 'ER_USER_IMPERSONATEUSER' },
  { identifier: 'ER_TICKET_VIEWTICKET' },
  { identifier: 'ER_TICKET_CREATETICKET' },
  { identifier: 'ER_EQUIPMENT_VIEWEQUIPMENT' },
  { identifier: 'ER_EQUIPMENT_UPDATEEQUIPMENT' },
  { identifier: 'ER_CONTRACT_VIEWCONTRACT' },
  { identifier: 'ER_PLANNEDACTIVITIES_VIEWPLANNEDACTIVITIES' },
  { identifier: 'ER_PLANNEDACTIVITIES_RESCHEDULEPLANNEDACTIVITIES' },
  { identifier: 'ER_PLANNEDTRAININGS_VIEWPLANNEDACTIVITIES' },
  { identifier: 'ER_PLANNEDACTIVITIES_RESCHEDULEPLANNEDACTIVITIES' },
  { identifier: 'ER_WEBCENTERPORTAL_VIEWEPSREPORTS' },
  { identifier: 'ER_LN_IT_ADMIN' },
  { identifier: 'ER_LN_AUH_ADMIN' },
  { identifier: 'ER_LN_CYBERSECURITY_DASHBOARD' },
  { identifier: 'ER_SUBSCRIBE_TO_SECURITY_ADVISORIES' },
  { identifier: 'ER_VIEW_SECURITY_ADVISORIES' },
  { identifier: 'ER_LN_SECURITY_ADVISORY_AUTHOR' },
  { identifier: 'ER_SERVICEPARTS_VIEW' }
];

export const CdGBUserRoles: UserRoles[] = [
  { identifier: 'ER_LN_USER' },
  // { identifier: 'ER_LN_CUSTOMER_ADMIN' },
  // { identifier: 'ER_USER_IMPERSONATEUSER' },
  { identifier: 'ER_TICKET_VIEWTICKET' },
  { identifier: 'ER_TICKET_CREATETICKET' },
  { identifier: 'ER_EQUIPMENT_VIEWEQUIPMENT' },
  { identifier: 'ER_EQUIPMENT_UPDATEEQUIPMENT' },
  { identifier: 'ER_CONTRACT_VIEWCONTRACT' },
  { identifier: 'ER_PLANNEDACTIVITIES_VIEWPLANNEDACTIVITIES' },
  { identifier: 'ER_PLANNEDACTIVITIES_RESCHEDULEPLANNEDACTIVITIES' },
  { identifier: 'ER_PLANNEDTRAININGS_VIEWPLANNEDACTIVITIES' },
  { identifier: 'ER_WEBCENTERPORTAL_VIEWEPSREPORTS' },
  // { identifier: 'ER_LN_IT_ADMIN' },
  { identifier: 'ER_LN_AUH_ADMIN' },
  { identifier: 'ER_LN_CYBERSECURITY_DASHBOARD' },
  { identifier: 'ER_SUBSCRIBE_TO_SECURITY_ADVISORIES' },
  { identifier: 'ER_VIEW_SECURITY_ADVISORIES' },
  { identifier: 'ER_LN_SECURITY_ADVISORY_AUTHOR' },
  { identifier: 'ER_SERVICEPARTS_VIEW' }
];

export const CdUSUserRoles: UserRoles[] = [
  { identifier: 'ER_LN_USER' },
  // { identifier: 'ER_LN_CUSTOMER_ADMIN' },
  // { identifier: 'ER_USER_IMPERSONATEUSER' },
  { identifier: 'ER_LN_IT_ADMIN' },
  { identifier: 'ER_TICKET_VIEWTICKET' },
  { identifier: 'ER_TICKET_CREATETICKET' },
  { identifier: 'ER_EQUIPMENT_VIEWEQUIPMENT' },
  { identifier: 'ER_EQUIPMENT_UPDATEEQUIPMENT' },
  { identifier: 'ER_CONTRACT_VIEWCONTRACT' },
  { identifier: 'ER_PLANNEDACTIVITIES_VIEWPLANNEDACTIVITIES' },
  { identifier: 'ER_PLANNEDACTIVITIES_RESCHEDULEPLANNEDACTIVITIES' },
  { identifier: 'ER_PLANNEDTRAININGS_VIEWPLANNEDACTIVITIES' },
  { identifier: 'ER_WEBCENTERPORTAL_VIEWEPSREPORTS' },
  { identifier: 'ER_FINANCIAL' },
  { identifier: 'ER_LN_CYBERSECURITY_DASHBOARD' },
  { identifier: 'ER_SUBSCRIBE_TO_SECURITY_ADVISORIES' },
  { identifier: 'ER_VIEW_SECURITY_ADVISORIES' },
  { identifier: 'ER_LN_SECURITY_ADVISORY_AUTHOR' },
  { identifier: 'ER_SERVICEPARTS_VIEW' }
];

export const CdAdminUserRoles: UserRoles[] = [
  { identifier: 'ER_LN_USER' },
  { identifier: 'ER_LN_COUNTRY_ADMIN' },
  { identifier: 'ER_LN_MASTER_ADMIN' },
  // { identifier: 'ER_USER_IMPERSONATEUSER' },
  { identifier: 'ER_TICKET_VIEWTICKET' },
  { identifier: 'ER_TICKET_CREATETICKET' },
  { identifier: 'ER_EQUIPMENT_VIEWEQUIPMENT' },
  { identifier: 'ER_EQUIPMENT_UPDATEEQUIPMENT' },
  { identifier: 'ER_CONTRACT_VIEWCONTRACT' },
  { identifier: 'ER_PLANNEDACTIVITIES_VIEWPLANNEDACTIVITIES' },
  { identifier: 'ER_PLANNEDACTIVITIES_RESCHEDULEPLANNEDACTIVITIES' },
  { identifier: 'ER_PARTNERORDER_VIEWPARTNERORDERLIST' },
  { identifier: 'ER_PARTNERORDER_VIEWPARTNERORDERDETAILS' },
  { identifier: 'ER_PARTNERORDER_UPDATEPARTNERORDER' },
  { identifier: 'ER_PLANNEDTRAININGS_VIEWPLANNEDACTIVITIES' },
  { identifier: 'ER_WEBCENTERPORTAL_VIEWEPSREPORTS' },
  { identifier: 'ER_SERVICEPARTS_VIEW' },
  { identifier: 'ER_LN_SECURITY_ADVISORY_AUTHOR' }
];

export const CdUserRoles = {
  SE_GB_SIM_ALL: CdGBUserRoles,
  SE_AE_SIM_ALL: CdArabUserRoles,
  SE_US_SIM_ALL: CdUSUserRoles,
  Z00264FH: CdAdminUserRoles
};

export const CdUserSettings = {
  subprocessorNotificationSubscribed: false,
  emailFrequency: EmailFrequency.NEVER,
  securityAdvisoriesSubscribed: true
};
