import pathToRegexp from 'path-to-regexp';
import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
  HttpResponse,
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { mergeMap, materialize, delay, dematerialize, map, tap } from 'rxjs/operators';

// models & services

import { User } from '../app/core/models/user';
import { ToasterService } from '../app/core/component-communication-services/toaster/toaster.service';

// rest endpoints

import { restEndPoint, configRestEndPoint } from '../app/core/core-constants.service';
import {
  systemUpdatesRestName,
  systemUpdateDetailRestName,
  systemUpdatesByEquipmentKey,
  equipmentSecretKeys
} from '../app/core/rest-services/system-updates-rest.service';
import {
  equipmentOptionsUpgradesRestName,
  equipmentDocumentsRestName,
  modalitiesRestName,
  modalitiesGBRestName,
  equipmentEnvironmentDataExistsRestName,
  equipmentEnvironmentOptionsRestName,
  equipmentEnvironmentDataRestName,
  equipmentsPiiDetailsRestName,
  lastViewedEquipmentRestName,
  postLastViewedRestName,
  myProfileCheckedRestName,
  addRemoveMyEquipmentsRestName,
  equipmentDetailsRestName
} from '../app/core/rest-services/equipment-rest-constants.service';
import { psrRestName, psrRestTypes } from '../app/core/rest-services/psr-rest-constants.service';
import { customersImpersonateRestName } from '../app/core/rest-services/customer-groups-rest.service';
import {
  ticketsAllRestName,
  ticketsIndividualRestName,
  ticketSalutationsRestName,
  ticketTitlesRestName,
  ticketTypedModalities,
  problemSeveritiesRestName
} from '../app/core/rest-services/ticket-rest-constants.service';

// clickdummy static mock data

import { CdCustomerContacts } from './cd-customers.mock';
import { CdEnvironmentConfig } from './cd-environment-config.mock';
import { CdEqUsage } from './cd-equipment-usage.mock';
import { CdMyEquipmentProfile, CdLastViewedEquipments, CdEqDocuments } from './cd-equipment.mock';
import { CdEnvironmentData, CdEnvironmentOption } from './cd-environment-data.mock';
import { CdModalityList } from './cd-modalities.mock';
import { CdPsrTypes } from './cd-professional-service-request.mock';
import {
  CdTicketSalutations,
  CdTicketTitles,
  CdTicketTypedModalities,
  CdTicketProblemSevereties
} from './cd-tickets.mock';
import { CdUserRoles, CdUserSettings } from './cd-users.mock';
import { generateDigitalCompassData, generateDigitalCompassDetailData } from './cd-digital-compass';
import { CdActivitiesTypes } from './cd-activities.mock';
import { CdDocumentSource } from './cd-document-source.mock';
import { generateSprDashboard, generateSprDashboardDetails } from './cd-spr.mock';

@Injectable()
export class MockHttpBackend implements HttpInterceptor {
  directusRestEndPoint = 'https://fleet-cms.click-solutions.com/public/_/';
  directusAuthHeader = 'Bearer abcd';

  currentUser: User;
  myProfileChecked = false;

  routingTable = [
    { route: url => url.indexOf(this.directusRestEndPoint) >= 0, func: 'nextHandler' },
    { route: url => url.indexOf('.json') >= 0, func: 'nextHandler' },
    {
      route: url => url.indexOf('.siemens-healthineers.') >= 0 && url.indexOf('listPageData') >= 0,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}custom/export/cd-news`
    },
    { route: url => url.indexOf('activities/count/open?equipmentKey=') >= 0, func: 'dataHandler', data: { count: 2 } },
    {
      route: url => url.indexOf('mdm/allowedMaterialNumbersInMDM?businessLine=') >= 0,
      func: 'getWhitelistedMaterialNumbers'
    },
    {
      route: url => url.indexOf('customerGroups?country=') >= 0,
      func: 'getCustomerGroupsByCountry'
    },
    {
      route: url => url.indexOf('customers?country=') >= 0,
      func: 'getCustomersByCountry'
    },
    { route: `${restEndPoint}activities/:id/details`, func: 'getActivitiesDetails' },
    { route: `${restEndPoint}activities/types`, func: 'dataHandler', data: CdActivitiesTypes },
    { route: `${restEndPoint}activities`, func: 'getActivities' },
    { route: `${restEndPoint}announcements`, func: 'getAnnouncements' },
    { route: `${restEndPoint}blacklist`, func: 'dataHandler', data: [] },
    { route: `${restEndPoint}blacklist/po-field`, func: 'dataHandler', data: [] },
    { route: `${restEndPoint}compass/optionAvailable`, func: 'dataHandler', data: { available: true } },
    { route: `${restEndPoint}compass/optionDetails`, func: 'getDigitalCompassOptionDetails' },
    { route: `${restEndPoint}compass`, func: 'getDigitalCompass' },
    { route: `${restEndPoint}contracts/:id/usageData`, func: 'getContractsUsageData' },
    { route: `${restEndPoint}contracts/:id`, func: 'getContract' },
    { route: `${restEndPoint}contracts`, func: 'getContracts' },
    { route: `${restEndPoint}customers/contacts`, func: 'dataHandler', data: CdCustomerContacts },
    { route: `${restEndPoint}environmentConfig`, func: 'getEnvironmentConfig' },
    { route: `${restEndPoint}equipments/for-my-filters`, func: 'getEquipment' },
    {
      route: `${restEndPoint}equipments/myProfile`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}custom/export/cd-my-equipment-profile`
    },
    { route: `${restEndPoint}equipments/security-whitepaper`, func: 'getDownloadUrl' },
    {
      route: `${restEndPoint}equipments/status`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}custom/export/cd-my-equipment-status`
    },
    { route: `${restEndPoint}equipments`, func: 'getEquipment' },
    { route: `${restEndPoint}equipments-tab/get-user-order-equipment-widget`, func: 'dataHandler', data: [] },
    { route: `${restEndPoint}favorites`, func: 'dataHandler', data: [] },
    { route: `${restEndPoint}invoices`, func: 'getInvoices' },
    { route: `${restEndPoint}logistic/deliveryMilestones/:ticketNumber`, func: 'getMilestones' },
    { route: `${restEndPoint}mdm/spaces`, func: 'dataHandler', data: {} },
    {
      route: `${restEndPoint}messages`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}custom/export/cd-bell-notifications`
    },
    { route: `${restEndPoint}psr/rwf/equipment/whitelist`, func: 'dataHandler', data: [] },
    { route: `${restEndPoint}psr/templates`, func: 'dataHandler', data: [] },
    { route: `${restEndPoint}reports/nyuReport/allowed`, func: 'dataHandler', data: false },
    {
      route: `${restEndPoint}security-advisories/active`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}custom/export/cd-security-advisories`
    },
    {
      route: `${restEndPoint}security-advisories/all`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}custom/export/cd-security-advisories`
    },
    { route: `${restEndPoint}security-advisories/attachments`, func: 'getSecAvAttachments' },
    { route: `${restEndPoint}security-advisories/download-url`, func: 'getSecAvAttachmentsUrl' },
    { route: `${restEndPoint}security-notifications/:id`, func: 'getSecurityNotificationsSingle' },
    {
      route: `${restEndPoint}security-notifications`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}custom/export/cd-security-notifications`
    },
    { route: `${restEndPoint}service-metrics/available`, func: 'dataHandler', data: { available: false } },
    { route: `${restEndPoint}service-metrics`, func: 'dataHandler', data: [] },
    { route: `${restEndPoint}soa_cases`, func: 'directusHandler', data: `${this.directusRestEndPoint}items/soa_cases` },
    { route: `${restEndPoint}source/docbox`, func: 'dataHandler', data: { documents: [{ name: null, list: [] }] } },
    { route: `${restEndPoint}source/list`, func: 'dataHandler', data: CdDocumentSource },
    { route: `${restEndPoint}source/product-line-document`, func: 'getEqDocuments' },
    { route: `${restEndPoint}source/service-report`, func: 'getEqDocuments' },
    { route: `${restEndPoint}source/technical-documentation`, func: 'getEqDocuments' },
    { route: `${restEndPoint}spare-parts/overview`, func: 'getSprSparePartsOverview' },
    { route: `${restEndPoint}spare-parts/details`, func: 'getSprSparePartsDetails' },
    { route: `${restEndPoint}spr/graph/dashboard`, func: 'getSprDashboard' },
    { route: `${restEndPoint}spr/graph/details`, func: 'getSprDetails' },
    { route: `${restEndPoint}spr/graph/getWidgetOrder`, func: 'dataHandler', data: [] },
    { route: `${restEndPoint}spr/graph/glossary`, func: 'getSprGlossary' },
    { route: `${restEndPoint}spr/graph/submittedWidgets`, func: 'dataHandler', data: true },
    {
      route: `${restEndPoint}spr/pdf/export`,
      func: 'fileHandler',
      data: `assets/data/files/service-performance-reporting.pdf`
    },
    { route: `${restEndPoint}srs/lastAvailable`, func: 'dataHandler', data: [] },
    { route: `${restEndPoint}srs/status/:id`, func: 'getSrsStatus' },
    { route: `${restEndPoint}system-updates/equipment/:id`, func: 'getSystemUpdatesByEquipmentKey' },
    {
      route: `${restEndPoint}equipment/documents/syngo-voucher/:id/whitelisted`,
      func: 'checkIfEquipmentIsWhitelistedForSyngoVoucher'
    },
    { route: `${restEndPoint}teamplay/data/available`, func: 'dataHandler', data: true },
    {
      route: `${restEndPoint}teamplay/data`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}custom/export/cd-teamplay-widgets-dashboard`
    },
    { route: `${restEndPoint}teamplay/detail/:id`, func: 'getTeamplayUsage' },
    { route: `${restEndPoint}trainings`, func: 'getTrainings' },
    { route: `${restEndPoint}tubes/details`, func: 'getSprTubeDetails' },
    { route: `${restEndPoint}users/assignedCustomers`, func: 'getAssignedCustomer' },
    { route: `${restEndPoint}users/intro/introShown `, func: 'dataHandler', data: { shown: true } },
    { route: `${restEndPoint}users/logout`, func: 'getLogoutUser' },
    { route: `${restEndPoint}users/self/audit`, func: 'dataHandler', data: { initialMyNotificationsShown: false } },
    {
      route: `${restEndPoint}users/self/roles`,
      func: 'dataHandler',
      data: CdUserRoles[localStorage.getItem('mockUserGid')] || {}
    },
    { route: `${restEndPoint}users/self/settings/mycustomers`, func: 'getAssignedCustomer' },
    { route: `${restEndPoint}users/self/settings`, func: 'dataHandler', data: CdUserSettings },
    { route: `${restEndPoint}users/self`, func: 'getUsers' },
    {
      route: `${restEndPoint}varian_dose_types`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}items/varain_dosetypes`
    },
    {
      route: `${restEndPoint}varian_machine_performance_average_times`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}items/varian_machine_performance_average_times`
    },
    {
      route: `${restEndPoint}varian_machine_performance_case_dates`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}items/varian_machine_performance_case_dates`
    },
    {
      route: `${restEndPoint}varian_machine_utilization_gated_treatments`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}items/varian_machine_utilization_gated_treatments`
    },
    {
      route: `${restEndPoint}varian_machine_utilization_image_type`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}items/varian_machine_utilization_image_type`
    },
    {
      route: `${restEndPoint}varian_machine_utilization_modalities`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}items/varian_machine_utilization_modalities`
    },
    {
      route: `${restEndPoint}varian_machine_utilization_modality_dates`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}items/varian_machine_utilization_modality_dates?fields=*.*`
    },
    {
      route: `${restEndPoint}varian_machineperformance_checkdates`,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}items/varian_machineperformance_checkdates`
    },
    {
      route: `${restEndPoint}wescan/isWeScanUser`,
      func: 'isWeScanUser'
    },
    {
      route: `${restEndPoint}equipments/systems`,
      func: 'getOperatingSystemStatusesForEquipments'
    },
    {
      route: `${restEndPoint}system-updates/cves`,
      func: 'getSystemUpdateCves'
    },
    {
      route: `${restEndPoint}favorites/count`,
      func: 'dataHandler',
      data: 0
    },
    { route: addRemoveMyEquipmentsRestName, func: 'getMyProfileId' },
    { route: configRestEndPoint, func: 'dataHandler', data: { status: 'DOWN' } },
    { route: customersImpersonateRestName, func: 'dataHandler', data: {} },
    { route: equipmentDetailsRestName, func: 'getEquipmentDetails' },
    { route: equipmentDocumentsRestName, func: 'getEquipmentDocuments' },
    { route: equipmentEnvironmentDataExistsRestName, func: 'dataHandler', data: true },
    { route: equipmentEnvironmentDataRestName, func: 'getEquipmentEnvironmentData' },
    { route: equipmentEnvironmentOptionsRestName, func: 'dataHandler', data: CdEnvironmentOption },
    { route: equipmentOptionsUpgradesRestName, func: 'getOptionsUpgrades' },
    { route: equipmentSecretKeys, func: 'dataHandler', data: [] },
    { route: equipmentsPiiDetailsRestName, func: 'getPiisEq' },
    { route: lastViewedEquipmentRestName, func: 'dataHandler', data: CdLastViewedEquipments },
    { route: modalitiesGBRestName, func: 'getModalitiesGB' },
    {
      route: modalitiesRestName,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}custom/export/cd-modalities`
    },
    { route: myProfileCheckedRestName, func: 'getMyProfileSelected' },
    { route: postLastViewedRestName, func: 'dataHandler', data: '' },
    { route: problemSeveritiesRestName, func: 'dataHandler', data: CdTicketProblemSevereties },
    { route: psrRestName, func: 'getProfessionalServiceRequests' },
    { route: psrRestTypes, func: 'dataHandler', data: CdPsrTypes },
    { route: systemUpdateDetailRestName, func: 'getSystemUpdatesDetails' },
    {
      route: systemUpdatesRestName,
      func: 'directusHandler',
      data: `${this.directusRestEndPoint}custom/export/cd-system-updates`
    },
    { route: ticketSalutationsRestName, func: 'dataHandler', data: CdTicketSalutations },
    { route: ticketTitlesRestName, func: 'dataHandler', data: CdTicketTitles },
    { route: ticketTypedModalities, func: 'dataHandler', data: CdTicketTypedModalities },
    { route: ticketsAllRestName, func: 'getTickets' },
    { route: ticketsIndividualRestName, func: 'getTicket' }
  ];

  constructor(
    private toast: ToasterService,
    private router: Router,
    private environmentData: CdEnvironmentData,
    private http: HttpClient
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const routes = this.routingTable.filter(el =>
      typeof el.route === 'function' ? el.route(req.url) : pathToRegexp(el.route).test(req.url)
    );
    let route = routes[0];
    if (routes.length > 1) {
      const routesNoKeys = routes.filter(el => typeof el.route === 'function' || el.route.indexOf(':') === -1);
      if (routesNoKeys.length === 1) {
        route = routesNoKeys[0];
      } else {
        console.warn(`Multiple routes for ${req.url}`, routes);
      }
    }

    if (route && route.func && this[route.func] && typeof this[route.func] === 'function') {
      if (route.data !== undefined) {
        return this[route.func](req, next, route.data);
      } else {
        return this[route.func](req, next);
      }
    }

    if (req.url) {
      console.warn('No mock data:', req.url);
    }
    return this.dataHandler(req, next, null);
  }

  private nextHandler(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req);
  }

  private customHandler(req: HttpRequest<any>, next: HttpHandler, url: string, callbackFn, params = null) {
    const newReqParams = {
      url: url,
      setHeaders: {
        Authorization: this.directusAuthHeader
      }
    };
    if (params) {
      newReqParams['setParams'] = params;
    }

    const newReq = req.clone(newReqParams);
    return next.handle(newReq).pipe(
      map((event: any) => {
        if (event instanceof HttpResponse) {
          const body = callbackFn(event);
          return event.clone({
            body: body
          });
        }
      }),
      tap((event: any) => {
        if (event instanceof HttpErrorResponse) {
          return this.catchErrors(event);
        }
      })
    );
  }

  private directusHandler(req: HttpRequest<any>, next: HttpHandler, url: string) {
    return this.customHandler(req, next, url, event => event.body);
  }

  private dataHandler(_req: HttpRequest<any>, _next: HttpHandler, data: any, observable = null) {
    const observable$ = observable ? observable : of(null);
    return observable$.pipe(
      mergeMap(pipeData => {
        return of(new HttpResponse({ status: 200, body: observable ? pipeData : data }));
      }),
      materialize(), // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      delay(500),
      dematerialize()
    );
  }

  private fileHandler(req: HttpRequest<any>, next: HttpHandler, url: string) {
    const newReqParams = {
      url: url,
      method: 'GET'
    };

    const newReq = req.clone(newReqParams);
    return this.customHandler(newReq, next, url, event => event.body);
  }

  private getDirectusData(url) {
    const options = {
      headers: new HttpHeaders({
        Authorization: this.directusAuthHeader
      })
    };
    return this.http.get(url, options);
  }

  private getActivities(req: HttpRequest<any>, next: HttpHandler) {
    const userId = localStorage.getItem('mockUserGid');
    return this.customHandler(
      req,
      next,
      `${this.directusRestEndPoint}custom/export/cd-activities`,
      event => {
        let listA = event.body;
        const statusFilter = req.params.get('statusFilter') ? req.params.get('statusFilter') : null;
        const equipmentKeyA = req.params.get('equipmentKey') ? req.params.get('equipmentKey') : null;
        if (statusFilter && statusFilter !== '3') {
          listA = listA.filter(item => item.activityStatus === statusFilter);
        }
        if (statusFilter && statusFilter === '3') {
          listA = listA;
        }
        if (equipmentKeyA) {
          listA = listA.filter(item => item.equipmentKey === equipmentKeyA);
        }
        return listA;
      },
      { user: userId }
    );
  }

  private getActivitiesDetails(req: HttpRequest<any>, next: HttpHandler) {
    const userId = localStorage.getItem('mockUserGid');
    const reg = pathToRegexp(`${restEndPoint}activities/:id/details`);
    const activityId = req.url.match(reg)[1];
    return this.customHandler(
      req,
      next,
      `${this.directusRestEndPoint}custom/export/cd-activities`,
      event => {
        const activity = event.body.find(a => a.ticketKey === activityId) || {};
        return activity;
      },
      { user: userId }
    );
  }

  private getAnnouncements(req: HttpRequest<any>, next: HttpHandler) {
    return this.customHandler(req, next, `${this.directusRestEndPoint}items/announcements`, event => {
      return event.body.data.find(el => el['set_active']) || [];
    });
  }

  private isWeScanUser(req: HttpRequest<any>, next: HttpHandler) {
    return this.dataHandler(null, null, false, of(false));
  }

  private getOperatingSystemStatusesForEquipments(req: HttpRequest<any>, next: HttpHandler) {
    return this.dataHandler(null, null, false, of([]));
  }

  private getWhitelistedMaterialNumbers(req: HttpRequest<any>, next: HttpHandler) {
    const mockData = ['material1', 'material2', 'material3'];
    return this.dataHandler(null, null, mockData, of(mockData));
  }

  private getCustomerGroupsByCountry(req: HttpRequest<any>, next: HttpHandler) {
    return this.dataHandler(null, null, false, of([]));
  }

  private getCustomersByCountry(req: HttpRequest<any>, next: HttpHandler) {
    return this.dataHandler(null, null, false, of([]));
  }

  private checkIfEquipmentIsWhitelistedForSyngoVoucher(req: HttpRequest<any>, next: HttpHandler) {
    return this.dataHandler(null, null, false, of(true));
  }

  private getSystemUpdateCves(req: HttpRequest<any>, next: HttpHandler) {
    return this.dataHandler(null, null, false, of([]));
  }

  private getAssignedCustomer(req: HttpRequest<any>, next: HttpHandler) {
    if (req.method === 'POST') {
      return this.directusHandler(req, next, this.directusRestEndPoint + 'custom/export/cd-assigned-customers-post');
    } else {
      const userId = localStorage.getItem('mockUserGid');
      return this.customHandler(
        req,
        next,
        this.directusRestEndPoint + 'custom/export/cd-assigned-customers',
        event => event.body,
        { user: userId }
      );
    }
  }

  private getContract(req: HttpRequest<any>, next: HttpHandler) {
    const reg = pathToRegexp(`${restEndPoint}contracts/:id`);
    const contractId = req.url.match(reg)[1];
    return this.customHandler(req, next, `${this.directusRestEndPoint}custom/export/cd-contracts`, event => {
      const contract = event.body.find(a => a.contractNumber === contractId) || {};
      return contract.details ? contract.details : {};
    });
  }

  private getContracts(req: HttpRequest<any>, next: HttpHandler) {
    return this.customHandler(req, next, `${this.directusRestEndPoint}custom/export/cd-contracts`, event => {
      let listC = event.body;
      const contractsStatusFilter = req.params.get('statusFilter') ? req.params.get('statusFilter') : null;
      if (contractsStatusFilter) {
        listC = listC.filter(item => contractsStatusFilter.split(',').includes(item.contractStatusId));
      }
      return listC;
    });
  }

  private getContractsUsageData(req: HttpRequest<any>, next: HttpHandler) {
    const id = req.url.split('/').reverse()[1];
    const data = id.toString() === '7865600076' ? CdEqUsage : {};
    return this.dataHandler(req, next, data);
  }

  private getDigitalCompass(req: HttpRequest<any>, next: HttpHandler) {
    const userGid = localStorage.getItem('mockUserGid');

    const articles$ = this.getDirectusData(
      this.directusRestEndPoint + `items/clinical_articles?fields=*.*&filter[user_gid][contains]=${userGid}`
    );
    const diseases$ = this.getDirectusData(this.directusRestEndPoint + 'items/clinical_diseases?fields=*.*');
    const fields$ = this.getDirectusData(this.directusRestEndPoint + 'items/clinical_fields?fields=*.*');
    const graph$ = this.getDirectusData(
      this.directusRestEndPoint + `items/clinical_graph?fields=*.*&filter[user_gid][contains]=${userGid}`
    );
    const options$ = this.getDirectusData(
      this.directusRestEndPoint + `items/clinical_options?fields=*.*&filter[user_gid][contains]=${userGid}`
    );
    const modalities$ = this.getDirectusData(this.directusRestEndPoint + 'items/modalities?fields=*.*');
    const equipment$ = this.getDirectusData(this.directusRestEndPoint + 'items/equipment?fields=*.*');
    const data$ = combineLatest([articles$, diseases$, fields$, graph$, options$, modalities$, equipment$]).pipe(
      map(([articles, diseases, fields, graph, options, modalities, equipment]) => {
        return generateDigitalCompassData({ articles, diseases, fields, graph, options, modalities, equipment });
      })
    );
    return this.dataHandler(req, next, null, data$);
  }

  private getDigitalCompassOptionDetails(req: HttpRequest<any>, next: HttpHandler) {
    const options$ = this.getDirectusData(this.directusRestEndPoint + 'items/clinical_options?fields=*.*');
    const data$ = combineLatest([options$]).pipe(
      map(([options]) => {
        const guid = req.params.get('marketingNameGUID') || req.params.get('localizedGUID') || '';
        return generateDigitalCompassDetailData(guid, options);
      })
    );
    return this.dataHandler(req, next, null, data$);
  }

  private getDownloadUrl(req: HttpRequest<any>, _next: HttpHandler) {
    const path = req.params.get('path');
    if (path) {
      return of(new HttpResponse({ status: 200, body: { url: path } }));
    }
    return throwError(() => new HttpErrorResponse({ status: 401 }));
  }

  private getEnvironmentConfig(req: HttpRequest<any>, next: HttpHandler) {
    const environment = CdEnvironmentConfig;
    if (location.hostname === 'localhost') {
      environment.WALKME_CONFIGURATION_IDENTIFIER = null;
    }
    return this.dataHandler(req, next, environment);
  }

  private getEqDocuments(req: HttpRequest<any>, next: HttpHandler) {
    const param = req.url.split('/').reverse()[0];
    const eqDocId = req.params.get('equipmentKey');

    return this.customHandler(req, next, this.directusRestEndPoint + 'custom/export/cd-equipments-details', event => {
      const attachDocs = event.body.attachments.find(item => item.id.toString() === eqDocId.toString());
      const eqDocs = attachDocs ? attachDocs.attachments.filter(item => item.attachmentCat === param) : [];
      return {
        documents: eqDocs.length > 0 ? [{ name: param, list: eqDocs }] : []
      };
    });
  }

  private getEquipment(req: HttpRequest<any>, next: HttpHandler) {
    const userId = localStorage.getItem('mockUserGid');
    return this.customHandler(
      req,
      next,
      this.directusRestEndPoint + 'custom/export/cd-equipments',
      event => event.body,
      { user: userId }
    );
  }

  private getEquipmentDetails(req: HttpRequest<any>, next: HttpHandler) {
    const reg = pathToRegexp(equipmentDetailsRestName);
    const id = req.url.match(reg)[1];

    return this.customHandler(req, next, this.directusRestEndPoint + 'custom/export/cd-equipments-details', event => {
      const equipmentDetails = {
        attachments: [],
        components: []
      };
      equipmentDetails.components = event.body.components.filter(d => d.equipmentKey === id.toString());
      const attachDocs = event.body.attachments.filter(item => item.id === id);
      equipmentDetails.attachments = attachDocs.length > 0 ? attachDocs[0].attachments : [];
      return equipmentDetails;
    });
  }

  private getEquipmentDocuments(req: HttpRequest<any>, next: HttpHandler) {
    const reg = pathToRegexp(equipmentDocumentsRestName);
    const eqId = req.url.match(reg)[1];
    const docs = CdEqDocuments.find(d => d.id === eqId);
    const data = docs.details ? docs.details : {};
    return this.dataHandler(req, next, data);
  }

  private getEquipmentEnvironmentData(req: HttpRequest<any>, _next: HttpHandler) {
    this.environmentData.request = req;
    return of(
      new HttpResponse({
        status: 200,
        body: this.environmentData.getData()
      })
    );
  }

  private getInvoices(req: HttpRequest<any>, next: HttpHandler) {
    return this.customHandler(req, next, `${this.directusRestEndPoint}custom/export/cd-invoices`, event => {
      let list = event.body;
      const invoiceType = req.params.get('invoiceType') ? req.params.get('invoiceType') : null;
      if (invoiceType) {
        list = list.filter(item => item.invoiceType === invoiceType);
      }
      return list;
    });
  }

  private getLogoutUser(req: HttpRequest<any>, next: HttpHandler) {
    localStorage.removeItem('mockUserGid');
    this.currentUser = null;
    return this.dataHandler(req, next, {});
  }

  private getMilestones(req: HttpRequest<any>, next: HttpHandler) {
    const ticketId = req.url.split('/').reverse()[0];
    return this.customHandler(
      req,
      next,
      `${this.directusRestEndPoint}custom/export/cd-parts-delivery-milestones`,
      event => {
        const milestones = event.body.find(t => t.id === ticketId);
        return milestones ? milestones.data : null;
      }
    );
  }

  private getModalitiesGB(req: HttpRequest<any>, next: HttpHandler) {
    const listM: string[] = [];
    CdModalityList.forEach(el => {
      listM.push(el.modalityDescription);
    });

    return this.dataHandler(req, next, listM);
  }

  private getMyProfileId(req: HttpRequest<any>, next: HttpHandler) {
    const reg = pathToRegexp(addRemoveMyEquipmentsRestName);
    const equipId = req.url.match(reg)[1];
    if (CdMyEquipmentProfile.equipment.find(el => el === equipId)) {
      CdMyEquipmentProfile.equipment = CdMyEquipmentProfile.equipment.filter(el => el !== equipId);
    } else {
      CdMyEquipmentProfile.equipment.push(equipId);
    }
    return this.dataHandler(req, next, {});
  }

  private getMyProfileSelected(req: HttpRequest<any>, next: HttpHandler) {
    if (req.method === 'POST') {
      this.myProfileChecked = !this.myProfileChecked;
    }
    return this.dataHandler(req, next, { checked: this.myProfileChecked });
  }

  private getOptionsUpgrades(req: HttpRequest<any>, next: HttpHandler) {
    return this.customHandler(req, next, `${this.directusRestEndPoint}custom/export/cd-options-upgrades`, event => {
      const reg = pathToRegexp(equipmentOptionsUpgradesRestName);
      const eId = req.url.match(reg)[1];
      let value = [];
      const eValue = event.body.find(d => d.id === eId);
      if (eValue) {
        value = eValue.value;
      }
      return value;
    });
  }

  private getPiisEq(req: HttpRequest<any>, next: HttpHandler) {
    const equipmentKey = req.url.split('/').reverse()[1];
    return this.customHandler(req, next, `${this.directusRestEndPoint}custom/export/cd-security`, event => event.body, {
      equipmentKey: equipmentKey
    });
  }

  private getProfessionalServiceRequests(req: HttpRequest<any>, next: HttpHandler) {
    if (req.method === 'GET') {
      return this.directusHandler(
        req,
        next,
        this.directusRestEndPoint + 'custom/export/cd-professional-service-requests'
      );
    } else {
      return this.directusHandler(
        req,
        next,
        this.directusRestEndPoint + 'custom/export/cd-professional-service-requests-post'
      );
    }
  }

  private getSecAvAttachments(req: HttpRequest<any>, next: HttpHandler) {
    return this.customHandler(req, next, this.directusRestEndPoint + 'custom/export/cd-security-advisories', event => {
      const files = event.body.find(item => item.id.toString() === req.params.get('id'));
      const data = files && files.files.length > 0 ? files.files : [];
      return data;
    });
  }

  private getSecAvAttachmentsUrl(req: HttpRequest<any>, next: HttpHandler) {
    return this.customHandler(req, next, this.directusRestEndPoint + 'custom/export/cd-security-advisories', event => {
      const files = event.body.find(item => item.id.toString() === req.params.get('id'));
      const file = files.files.find(item => item.type === req.params.get('fileType'));
      const data = file ? { url: file.fullPath } : {};
      return data;
    });
  }

  private getSecurityNotificationsSingle(req: HttpRequest<any>, next: HttpHandler) {
    const idSingle = req.url.split('/').reverse()[0];
    return this.customHandler(
      req,
      next,
      `${this.directusRestEndPoint}custom/export/cd-security-notifications`,
      event => {
        const item = event.body.find(ele => {
          return ele.id.toString() === idSingle.toString();
        });
        return item ? item : {};
      }
    );
  }

  private getSprDashboard(req: HttpRequest<any>, next: HttpHandler) {
    const sprWidgets$ = this.getDirectusData(this.directusRestEndPoint + 'items/spr_widgets');
    const equipment$ = this.getDirectusData(this.directusRestEndPoint + 'items/equipment');
    const modalities$ = this.getDirectusData(this.directusRestEndPoint + 'items/modalities');
    const customers$ = this.getDirectusData(this.directusRestEndPoint + 'items/assigned_customer');
    const data$ = combineLatest([sprWidgets$, equipment$, modalities$, customers$]).pipe(
      map(([widgets, equipment, modalities, customers]) => {
        return generateSprDashboard({ widgets, equipment, modalities, customers });
      })
    );

    return this.dataHandler(req, next, null, data$);
  }

  private getSprDetails(req: HttpRequest<any>, next: HttpHandler) {
    const graphId = req.body.graphId.toString();
    const detailsType = req.body.detailsType.toString();
    const sprWidgets$ = this.getDirectusData(this.directusRestEndPoint + 'items/spr_widgets');
    const equipment$ = this.getDirectusData(this.directusRestEndPoint + 'items/equipment');
    const modalities$ = this.getDirectusData(this.directusRestEndPoint + 'items/modalities');
    const tickets$ = this.getDirectusData(this.directusRestEndPoint + 'items/tickets');
    const sprDetails$ = this.getDirectusData(this.directusRestEndPoint + 'items/spr_widgets_details?fields=*.*');
    const customers$ = this.getDirectusData(this.directusRestEndPoint + 'items/assigned_customer');
    const data$ = combineLatest([sprWidgets$, equipment$, modalities$, tickets$, sprDetails$, customers$]).pipe(
      map(([widgets, equipment, modalities, tickets, details, customers]) => {
        return generateSprDashboardDetails({
          widgets,
          equipment,
          modalities,
          graphId,
          tickets,
          detailsType,
          details,
          customers
        });
      })
    );

    return this.dataHandler(req, next, null, data$);
  }

  private getSprGlossary(req: HttpRequest<any>, next: HttpHandler) {
    return this.customHandler(req, next, `${this.directusRestEndPoint}items/spr_glossary_kpis?fields=*.*`, event => {
      const body = event.body;
      const result = {
        glossaries: []
      };
      if (body && body.data) {
        const data = _.groupBy(body.data, 'widget.id');
        result.glossaries = Object.keys(data).map(key => {
          return {
            id: key,
            name: data[key][0]['widget']['name'],
            kpis: data[key].map(el => ({ id: el.id, name: el.name, description: el.description }))
          };
        });
      }
      return result;
    });
  }

  private getSprTubeDetails(req: HttpRequest<any>, next: HttpHandler) {
    const tubeguard$ = this.getDirectusData(
      this.directusRestEndPoint + 'items/spr_tubeguard?fields=*.*,equipment.*,equipment.modality.*'
    );
    const tickets$ = this.getDirectusData(this.directusRestEndPoint + 'items/tickets');
    const data$ = combineLatest([tubeguard$, tickets$]).pipe(
      map(([tubeguard, tickets]) => {
        const res = (<any>tubeguard).data.map(el => {
          const eqTickets = (<any>tickets).data.filter(
            t => t.equipment_key.toString() === el.equipment.equipment_key.toString()
          );

          return {
            equipmentKey: el.equipment.equipment_key,
            functionalLocation: el.equipment.location,
            productName: el.equipment.product_name,
            serialNumber: el.equipment.serial_number,
            modalityDesc: el.equipment.modality.modality_description,
            lastCheckedDate: el.lastcheckeddate,
            tubes: el.tubes,
            serviceActions: eqTickets.map(t => {
              const today = new Date();
              const date = new Date(today.setDate(today.getDate() + t.planned_start_date));
              return {
                date: date.toISOString().split('T')[0],
                eventInstruction: t.tubeguard_event_instruction,
                ticketNumber: t.ticket_number
              };
            })
          };
        });

        return {
          name: 'TubeGuard',
          items: res
        };
      })
    );

    return this.dataHandler(req, next, null, data$);
  }

  private getSprSparePartsOverview(req: HttpRequest<any>, next: HttpHandler) {
    const spareParts$ = this.getDirectusData(this.directusRestEndPoint + 'items/spr_spare_parts?fields=*.*');
    const data$ = combineLatest([spareParts$]).pipe(
      map(([parts]) => {
        const res = (<any>parts).data.map(el => {
          return {
            modalityDescription: el.modality.modality_description,
            ticketsCount: el.tickets_count,
            sparePartsCount: el.spare_parts_count
          };
        });

        return {
          name: 'Spare Parts',
          items: res
        };
      })
    );

    return this.dataHandler(req, next, null, data$);
  }

  private getSprSparePartsDetails(req: HttpRequest<any>, next: HttpHandler) {
    const modality = req.body.filters.find(f => f.name === 'modalityCodes').values[0];
    const tickets$ = this.getDirectusData(this.directusRestEndPoint + 'items/tickets?fields=*.*');
    const equipment$ = this.getDirectusData(this.directusRestEndPoint + 'items/equipment?fields=*.*');
    const data$ = combineLatest([tickets$, equipment$]).pipe(
      map(([tickets, equipment]) => {
        const eq = (<any>equipment).data.filter(e => e.modality.modality_description === modality);
        const res = [];
        eq.forEach(el => {
          const eqTickets = (<any>tickets).data.filter(t => t.equipment_key.toString() === el.equipment_key.toString());

          eqTickets.forEach(t => {
            const detail = {
              equipmentKey: el.equipment_key,
              functionalLocation: el.location,
              productName: el.product_name,
              serialNumber: el.serial_number,
              ticketNumber: t.ticket_number,
              date: new Date().toISOString().split('T')[0],
              descriptions: []
            };
            res.push(detail);
          });
        });

        return {
          name: 'Spare Parts',
          items: res
        };
      })
    );

    return this.dataHandler(req, next, null, data$);
  }

  private getSrsStatus(req: HttpRequest<any>, next: HttpHandler) {
    const userId = localStorage.getItem('mockUserGid');
    return this.customHandler(
      req,
      next,
      `${this.directusRestEndPoint}custom/export/cd-equipments`,
      event => {
        const eqKey = req.url.split('/').reverse();
        const eq = event.body.find(e => e.key.toString() === eqKey[0].toString());

        return {
          equipmentKey: eq.key,
          status: eq.srsStatus,
          lastUpdate: new Date()
        };
      },
      { user: userId }
    );
  }

  private getSystemUpdatesByEquipmentKey(req: HttpRequest<any>, next: HttpHandler) {
    return this.customHandler(req, next, `${this.directusRestEndPoint}custom/export/cd-system-updates`, event => {
      const eqKeyUpdates = req.url.split('/').reverse();
      return event.body.filter(item => (item.equipmentItems || []).some(i => i.key === eqKeyUpdates[0].toString()));
    });
  }

  private getSystemUpdatesDetails(req: HttpRequest<any>, next: HttpHandler) {
    return this.customHandler(req, next, `${this.directusRestEndPoint}custom/export/cd-system-updates`, event => {
      return event.body.filter(item => item.content === req.params.get('updateNumber'))[0];
    });
  }

  private getTeamplayUsage(req: HttpRequest<any>, next: HttpHandler) {
    const reg = pathToRegexp(`${restEndPoint}teamplay/detail/:id`);
    const serialId = req.url.match(reg)[1];
    return this.customHandler(
      req,
      next,
      `${this.directusRestEndPoint}custom/export/cd-teamplay-usage`,
      event => event.body,
      { serial: serialId }
    );
  }

  private getTicket(req: HttpRequest<any>, next: HttpHandler) {
    const reg = pathToRegexp(ticketsIndividualRestName);
    const ticketId = req.url.match(reg)[1];
    return this.customHandler(req, next, `${this.directusRestEndPoint}custom/export/cd-tickets`, event => {
      const ticket = event.body.find(t => t.ticketKey === ticketId);
      return ticket;
    });
  }

  private getTickets(req: HttpRequest<any>, next: HttpHandler) {
    return this.customHandler(req, next, `${this.directusRestEndPoint}custom/export/cd-tickets`, event => {
      let listT = event.body;
      const equipementKey = req.params.get('equipmentKey') ? req.params.get('equipmentKey') : null;
      const statusFilter = req.params.get('statusFilter') ? req.params.get('statusFilter') : null;
      const dateStartFilter = req.params.get('dateStartFilter') ? req.params.get('dateStartFilter') : null;
      const dateEndFilter = req.params.get('dateEndFilter') ? req.params.get('dateEndFilter') : null;
      if (equipementKey) {
        listT = listT.filter(item => item.equipmentKey === equipementKey);
      }
      if (statusFilter && statusFilter !== '3') {
        listT = listT.filter(item => item.ticketStatus === statusFilter);
      }
      if (dateStartFilter && dateStartFilter !== '01.01.1970') {
        listT = listT.filter(
          item => new Date(item.ticketCreationTimestamp) >= new Date(dateStartFilter.split('.').reverse().join('/'))
        );
      }
      if (dateEndFilter && dateEndFilter !== '01.01.2070') {
        listT = listT.filter(
          item => new Date(item.ticketCreationTimestamp) <= new Date(dateEndFilter.split('.').reverse().join('/'))
        );
      }
      return listT;
    });
  }

  private getTrainings(req: HttpRequest<any>, next: HttpHandler) {
    return this.customHandler(req, next, `${this.directusRestEndPoint}custom/export/cd-trainings`, event => {
      const training = event.body.find(
        item => item.equipmentKey.toString() === req.params.get('equipmentKey').toString()
      );
      return training.trainings;
    });
  }

  private getUsers(req: HttpRequest<any>, next: HttpHandler) {
    const userGid = localStorage.getItem('mockUserGid');
    if (!userGid) {
      this.router.navigate(['/welcome']);
      return of(new HttpErrorResponse({ status: 401 }));
    }

    return this.customHandler(req, next, '/assets/data/mocks/users.json', event => {
      const user = event.body.filter(item => item.gid === userGid)[0];
      if (!user || user === undefined) {
        return throwError(() => new HttpErrorResponse({ status: 401, url: req.url }));
      }
      this.currentUser = user;
      return user;
    });
  }

  private catchErrors(event) {
    if (event instanceof HttpErrorResponse) {
      switch (event.status) {
        case 401:
          // navigate to welcome
          this.router.navigate(['/welcome']);
          break;

        case 403:
          // check for self in url to find errors from /user/self or /user/self/roles
          // then logout session and show error message
          if (_.includes(event.url, 'self')) {
            this.router.navigate(['/welcome']);
            this.toastError('FRONTEND_LOGIN_ERROR_MESSAGE_AUTHORIZATION');
          }
          break;

        case 404:
          this.toastError('FRONTEND_GENERIC_ERROR_MESSAGE');
          break;

        // default case is anything else with status 400 or 500
        default:
          // show default error message
          let errorMessageKey = 'FRONTEND_GENERIC_ERROR_MESSAGE';

          // special cases for performance report and excel report
          if (_.includes(event.url, 'AsPdf')) {
            errorMessageKey = 'FRONTEND_SERVICE_PERFORMANCE_REPORT_ERROR_MESSAGE';
          } else if (_.includes(event.url, 'generateExcel')) {
            errorMessageKey = 'FRONTEND_EXCEL_REPORT_ERROR_MESSAGE';
          }
          this.toastError(errorMessageKey);
          break;
      }
    }
  }

  toastError(message: string) {
    const toast = {
      type: 'error',
      isBodyTranslationKey: true,
      body: message
    };
    this.toast.emitToast(toast);
  }
}

export const MockHttpBackendProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: MockHttpBackend,
  multi: true
};
